<template>
  <Layout>
    <div class="ppcontainer regcontainer">
      <Header
        :back="true"
        regulation="pipl"
      />
      <h1
        class="title"
        :dir="dir"
      >
        233渠道权限说明
      </h1>
      <h1 :dir="dir">
        为了保证您正常的游戏体验，我们可能向您申请获取以下权限：
      </h1>
      <div class="text scrollable">
        <table>
          <thead>
            <tr>
              <th>调用的设备权限</th>
              <th>权限对应的业务功能</th>
              <th>调用权限目的</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>储存权限</td>
              <td>下载和安装游戏更新内容</td>
              <td>拒绝授权将可能无法正常进行游戏</td>
            </tr>
            <tr>
              <td>电话权限</td>
              <td>
                获取设备标识（AndroidID、mac），生成帐号、保存和恢复游戏数据
              </td>
              <td>为了生成帐号并保存/恢复游戏数据</td>
            </tr>
            <tr>
              <td>后台运行权限</td>
              <td>允许程序在手机屏幕关闭后后台进程仍然运行，防止游戏进程断开</td>
              <td>确保返回游戏时无需重启游戏</td>
            </tr>
            <tr>
              <td>网络状态权限</td>
              <td>获取当前WiFi接入状态及WLAN热点信息</td>
              <td>确保游戏在联网情况下才可正常运行</td>
            </tr>
            <tr>
              <td>获取应用安装列表权限</td>
              <td>收集应用程序性能、崩溃等情况，为了预防恶意程序及安全运营</td>
              <td>必须收集应用安装列表以确保安全运营，避免恶意程序</td>
            </tr>
            <tr>
              <td>麦克风权限</td>
              <td>提供语音服务功能</td>
              <td>访问麦克风以提供语音服务功能</td>
            </tr>
            <tr>
              <td>READ_PHONE_STATE 读取电话状态(设备 IMSI/IMEI 号） </td>
              <td>【可选】读取手机设备标识等信息</td>
              <td>进行广告投放及广告监测归因、反作弊</td>
            </tr>
            <tr>
              <td>
                WRITE_EXTERNAL_STORAGE
                写入外置存储器
              </td>
              <td>【可选】允许应用程序写入外部存储</td>
              <td>应用下载广告投放及广告素材存储</td>
            </tr>
            <tr>
              <td>
                READ_EXTERNAL_STORAGE
                读取外置存储器
              </td>
              <td>
                【可选】允许应用程序读取外部存储
              </td>
              <td>应用下载广告投放及广告素材存储</td>
            </tr>
            <tr>
              <td>
                QUERY_ALL_PACKAGES
                应用软件列表
              </td>
              <td>【可选】获取应用软件列表</td>
              <td>进行广告投放及反作弊</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Header from '@components/Header.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,

  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.ppcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 20px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: justify;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 20px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  .scrollable {
    overflow-x: scroll;
    td {
      min-width: 240px;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
